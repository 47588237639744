<template>
  <v-card :loading="loading" :disabled="loading" class="mt-n10 moholand-shadow">
    <v-card-title>
      ایجاد {{ $route.meta.title }} جدید
      <v-spacer></v-spacer>

      <v-btn color="primary" :to="destinationRoute">
        <v-icon>mdi-undo-variant</v-icon>
        بازگشت
      </v-btn>
    </v-card-title>
    <v-card-subtitle>
      در این بخش میتوانید {{ $route.meta.title }} درست کنید.
    </v-card-subtitle>
    <v-card-text>
      <v-form ref="form">
        <v-form-base
          :model="model"
          :schema="schema"
          :row="rowAttribute"
          :col="{ cols: 12, md: 6 }"
        />
        <v-row align="center" justify="end" class="mt-8">
          <v-btn @click="clear" depressed>
            پاک کردن فرم
          </v-btn>

          <v-btn
            class="mx-4"
            dark
            color="success"
            @click="submit"
            :loading="loading"
          >
            {{ "ایجاد " + $route.meta.title + " جدید" }}
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import VFormBase from "vuetify-form-base";
import { formPostPrepare, formGetPrepare } from "@/constants/PanelFormHandler";

export default {
  data() {
    return {
      destinationRoute:
        this.$route.fullPath.slice(
          0,
          this.$route.fullPath.indexOf("create") - 1
        ) + (this.$route.query?.page ? "?page=" + this.$route.query?.page : ""),
      rowAttribute: {
        justify: "center",
        align: "center",
        noGutters: false,
      },
      // categories: [],
      model: {
        name: "",
        author_id: this.$store.state.userData.id || 0,
        slug: "",
        desc: "",
      },
      schema: {
        name: {
          type: "CustomInput",
          label: "عنوان",
          class: "required",
          rules: [Constants.rules.required],
        },
        slug: {
          type: "CustomInput",
          label: "اسلاگ",
          class: "required",
          rules: [Constants.rules.required, Constants.rules.english_string],
        },
        desc: {
          type: "MyTinyMCE",
          outlined: true,
          label: "محتوای صفحه",
          class: "required",
          rules: [Constants.rules.required],
          col: {
            cols: 12,
          },
        },
      },
      loading: false,
    };
  },
  components: {
    VFormBase,
  },
  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = formPostPrepare(this.model);
        // data.append("slug", this.model.name.replace(/\s/g, "-"));

        MyAxios.post(`/${this.$route.meta.api}/create`, data)
          .then((response) => {
            this.loading = false;
            this.$root.$emit("toast", {
              text: this.$route.meta.title + " با موفقیت ایجاد شد.",
              type: "success",
            });
            this.$router.replace(this.destinationRoute);
          })
          .catch((error) => {
            this.loading = false;
            if (parseInt(error.response.status) === 500) {
              this.$root.$emit("toast", {
                text:
                  "اسلاگ وارد شده تکراری است یا اشکالی در ثبت اطلاعات به وجود آمده است",
              });
            } else {
              this.$root.$emit("toast", {
                text: "مشکلی پیش آمده است. لطفا دوباره تلاش کنید.",
              });
            }
          });
      } else {
        this.$root.$emit("toast", {
          text: "لطفا اطلاعات را به درستی وارد نمایید.",
        });
      }
    },
    clear() {
      this.$refs.form.reset();
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
